<template>
    <div class="misceWrap">
        <div class="miscePage">
            <h3 >技術支持</h3>
            <p>最低硬件系統要求：</p>
            <p style="margin-left:15px;">1、可以接上互聯網的計算機；</p>
            <p style="margin-left:15px;" >2、區域寬帶；</p>
            <p style="margin-left:15px;" > 3、Windows , Mac OS X , Linux , Android操作系統其中之一； </p>
            <p style="margin-left:15px;" > 4、Internet Explorer瀏覽器v10.0 或以上，Mozilla Firefox (瀏覽器v3.0 或以上)，Opera (瀏覽器v8.0 或以上)，Chrome(瀏覽器v6.0 或以上)，Safari (瀏覽器v4.0 或以上)。 </p>
            </div>
            </div>
</template>

<script setup>

</script>

<style scoped lang='scss'>
.misceWrap {
    min-height: 58vh;
    text-align: center;

    .miscePage {
        padding: 40px 10px;
        max-width: 860px;
        margin: 0 auto;
        text-align: left;
        line-height: 1.8;
        word-break: break-all;
        font-size: 15px;
    }
    .miscePage {
        margin-top: 50px;
    }
}

</style>