<template>


    <div class="misceWrap">
        <h3>遊戲規範</h3>
        <div class="ruleTable">
         
            <table  >
                <table><tbody><tr><td colspan="2" class="theader sheader"> 獎金的分配須受賽馬會獎券奧門分公司的獎券規例所約制。 <p>獎金大致分配如下：</p></td></tr><tr><td class="first">各組獎金</td><td class="second">獎金分配（每澳門幣$10一注）</td></tr><tr><td class="first">第一組獎金</td><td class="second"> 45% x ﹝獎金基金減去第四、五、六及七組的總獎金﹞÷ 中獎單位每期頭獎獎金基金訂為不少於澳門幣800萬元。 </td></tr><tr><td class="first">第二組獎金</td><td class="second"> 15% x ﹝獎金基金減去第四、五、六及七組的總獎金﹞÷ 中獎單位 </td></tr><tr><td class="first">第三組獎金</td><td class="second"> 40% x ﹝獎金基金減去第四、五、六及七組的總獎金﹞÷ 中獎單位 </td></tr><tr><td class="first">第四組獎金</td><td class="second">每注澳門幣9,600元</td></tr><tr><td class="first">第五組獎金</td><td class="second">每注澳門幣640元</td></tr><tr><td class="first">第六組獎金</td><td class="second">每注澳門幣320元</td></tr><tr><td class="first">第七組獎金</td><td class="second">每注澳門幣40元</td></tr></tbody></table>
            </table>
            <div class="ruleMark">
                <p class="mark"> 獎金基金是指每期攪珠的總投注額的百分之五十四。 餘下的百分之四十六將根據博彩稅條例分配如下： </p>
                <p class="marktail">(i) 獎券收益的徵稅(百分之二十五)；</p><p class="marktail">(ii) 獎券基金(百分之十五)；</p>
                <p class="marktail">(iii) 賽馬會獎券奧門分公司的佣金(百分之六)。</p>
                <p class="mark"> 第一組、第二組及第三組獎金基金之分配百分率可作調整，以盡量確保在任何時間內，每一有關獎金以一注項單位作計算： </p>
                <p class="marktail">(i) 第一組獎金應最少為第二組獎金的兩倍；</p><p class="marktail">(ii) 第二組獎金應最少為第三組獎金的兩倍；</p>
                <p class="marktail">(iii) 第三組獎金應最少為第四組獎金的兩倍；</p>
                <p class="marktail"> (iv) 第四組、第五組、第六組及第七組獎金亦可根據獎券規例而被調整。 </p>
                <p class="mark">中獎登記</p>
                <p class="marktail"> 若獲中之獎金為每十元一注超過五百萬元，所有中獎彩票持票人士或經由電話投注服務的中獎人士，請於指定時間(公佈攪珠結果後至翌日下午5時)致賽馬會獎券奧門分公司在線服務系統登記。 </p><p class="mark">領取資金</p>
                <p class="marktail"> 所有獲中彩票，必須於有關攪珠的日期後起計60天內領取 (例子：於3月1日所舉行的攪珠的獲中彩票，可於3月1日當天至4月30日內任何一天領取)。 </p>
                <p class="mark">獎券規例</p>
                <p class="marktail"> 所有獎券注項均受賽馬會獎券奧門分公司規例所規限。如此通告與獎券規例有任何出入之處，一概以獎券規例為準。 </p></div>
        </div>
    </div>

</template>

<script setup>

</script>

<style scoped lang='scss'>
.main-page-full {
    width: 100%;
    .misceWrap {
        min-height: 58vh;
        text-align: center;
        font-size: 15px;
        .ruleTable {
            margin-top: 5px;
            padding: 0 10px 30px;
            border: solid var(--border-width) var(--color-border);
            border-top: 4px solid var(--color-primary);
            .ruleMark {
                width: 100%;
                max-width: 1060px;
                text-align: left;
                margin: 20px auto 0;
                .mark {
                    margin: 15px 0 4px;
                }
                .marktail {
                    padding-left: 2em;
                }
            }
            table {
                width: 100%;
                max-width: 1060px;
                text-align: left;
                margin: 20px auto 0;
                border-spacing: 0;
                border-collapse: collapse;
                border: solid var(--border-width) var(--color-text-light);
                td {
                    line-height: 2;
                    padding: 6px 10px;
                    border: solid var(--border-width) var(--color-text-light);
                }
            }
        }
        h3 {
            font-size: 24px;
            padding: 10px 0;
            text-align: center;
            color: var(--color-primary);
            color: #2c2c2c;
        }
        .miscePage {
            padding: 40px 10px;
            max-width: 860px;
            margin: 0 auto;
            text-align: left;
            line-height: 1.8;
            word-break: break-all;
            font-size: 15px;
            margin-top: 50px;
       
        }
    }
}
@media screen and (max-width: 610px) {
    h3{
        display: block !important;
        color: #d80011 !important;
        padding-top: 40px !important;
    }
    .ruleTable{
        border-top: none !important;
    }
    .about{
        margin-top: 0 !important;
  
        margin-bottom: 40px !important;
        padding: 10px 10px 20px !important  ;
        .mhide{
            display: none;
        }
  

    }
    

}
</style>