<template>
    <div class="misceWrap">
        <h3 style="padding-top: 80px; padding-bottom: 30px;">聯繫我們</h3>
        <p style="font-size: 14px;"> 澳門六合彩將竭誠為您提供最及時、最便捷、及滿意的服務。若有問題可透過以下方式與我們取得聯繫。 </p>
    </div>
</template>

<script setup>

</script>

<style scoped lang='scss'>
.misceWrap {
    min-height: 58vh;
    text-align: center;
    h3 {
        font-size: 24px;
        padding: 10px 0;
        text-align: center;
        color: var(--color-primary);
        color: #2c2c2c;
    }
}
</style>