<template>
    <div class="content">
        <div class="gameRule">
            <div class="title">公告列表</div>
            <div class="lotteryRule"></div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped lang='scss'>
    .content {
        width: 1170px;
        min-height: 210px;
        max-height: 770px;
        margin: 0 auto;
        .gameRule{
            padding: 0 30px;
            color: #2c2c2c;
            .title {
                margin-top: 40px;
                font-size: 24px;
                width: 1108px;
                height: 90px;
                line-height: 90px;
                text-align: center;
            }
            .lotteryRule{
                width: 1108px;
                margin: 0 auto 121px;
                min-height: 66px;
                max-height: 626px;
                background-color: #fff;
                border: 1px solid #dadada;
                border-top: 4px solid #d80011;
                position: relative;
            }
        }
    }
</style>