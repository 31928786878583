<template>
        <div class="popPlayer" v-if="videoShow">
        <div class="pmain">
            <div class="pheader">
                <div class="pinfo">
                    <span><strong>澳門六合彩</strong>第<b>{{codeArray.year +codeArray.periods}}</b>期<i>回放</i></span>
                    <p>開獎時間：{{codeArray.create_time}}</p>
                </div>
                <div class="pcode">
                    <b>開獎號碼：</b>
                    <ul class="drawCode">
                        <li :class="codeArray.color[ind]" v-for="(items,ind) in codeArray.open_number" :key="ind"><strong>{{items}}</strong><em>{{codeArray.sx[ind]}}</em></li>
                 
                    </ul>
                </div>
                <div class="pclose" @click="pclose"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025 1024" width="1em" height="1em" fill="currentColor"><path d="M513.344 0a512 512 0 1 0 0 1024 512 512 0 0 0 0-1024zm226.048 674.624l-54.528 56.896-171.52-164.928L341.952 731.52l-54.592-56.896L456.576 512 287.36 349.312l54.592-56.768 171.392 164.8 171.52-164.8 54.528 56.768L570.176 512l169.216 162.624z"></path></svg></div>
            </div>
            <div class="video-flv">
                <!-- <zibo :width="width"></zibo> -->
                <video :src="videoUrl+'/lottery/video/'+codeArray.year+'/2032/'+codeArray.year+codeArray.periods+'.mp4'" autoplay controls style="width:100%"></video>
            </div>
        </div>
    </div>
</template>

<script setup>
const emits = defineEmits(["pclose"]);
const videoUrl = process.env.VUE_APP_TYPE_CODE==2?"https://amcdn.zcgyjx.com":"https://sp.amkj.fyi";

const props = defineProps({
    videoShow:Boolean,
    codeArray:Object
})
console.log(props.codeArray)
const pclose = ()=>{
    emits('pclose',false)
}


</script>

<style scoped lang='scss'>
.popPlayer {
    z-index: 5;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.4);
    .pmain {
        flex: 1;
        margin: 0 10px;
        max-width: 640px;
        background: var(--color-background);
        .pheader {
            position: relative;
            height: 90px;
            width: 100%;
            padding: 15px;
            background-color: #fff;
            .pinfo {
                margin-top: 5px;
                span {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                }
                b {
                    color: var(--color-primary);
                    margin: 0 2px;
                }
                i {
                    display: block;
                    font-style: normal;
                    border-radius: 26px;
                    padding: 0 8px;
                    color: var(--color-primary-text);
                    background: var(--color-primary);
                    margin: 0 0 3px 15px;
                    font-size: 12px;
                    height: 20px;
                    line-height: 20px;
                }
                p {
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--color-text-light);
                    color: gray;
                }
                strong {
                    font-size: 16px;
                    margin: 0 7px 2px 0;
                    font-weight: bolder;
                }
            }
            .pcode {
                color: gray;
                height: 30px;
                width: 380px;
                line-height: 30px;
                font-size: 14px;
                position: relative;
                top: -40px;
                left: 254px;
                .drawCode {
                    list-style: none;
                    display: inline-flex;
                    em {
                        font-style: normal;
                        font-size: 12px;
                        line-height: 1em;
                        margin-top: -2px;
                        color: #525252;
                    }
                }
                b {
                    color: gray;
                    position: relative;
                    top: 17px;
                    left: -3px;
                    height: 30px;
                    width: 100px;
                    font-size: 14px;
                }
            }
            .pclose{
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                border-radius: 60px;
                color: var(--color-text-dark);
                background: var(--color-background);
                color: #d2d2d2;
            }
        }
        .video-flv {
            width: 100%;
            display: flex;
            position: relative;
            background: #000;
       
            video{
                width: 100%;
                max-height: 420px;
            }
        }
    }

}
@media screen and (min-width: 700px) and (max-width: 2000px) {
::v-deep{
    .svgmain .ball{
        animation: move forwards alternate ease-in;
        }
    .qiehuan {
        font-size: 20.5px !important;
    }
    .ball {
        width: 37.29px;
        height: 37.29px;
    }
    .history a {
        margin: 13px 0;
        border-radius: 9px;
        padding: 7px;
        box-shadow: 1.8px 1.8px 1.8px 0px #3b3b3b;
        font-size: 22px;
        border-radius: 0 ;
    }
    .kjtime {
        font-size: 28px;
    }
    .kjbox .kjball {
        height: 120px;
       
         .number {
            line-height: 53px;
            height: 53px;
            width: 53px;
            font-size: 20px;
        }
        .text {
            height: 66px;
            font-size: 30px;
            line-height: 75px;
        }
    }
    .teball{
        max-width: 66px;
    }
    .nextkj, .kjing {
        padding: 4.5px;
        letter-spacing: 1.8px;
        border-radius: 9px;
        font-size: 18px;
    }
    .ballbox {
        top: 30px;
        left: 156px;
        width: 321.5px;
        height: 321.5px;
    }
}
}
@media screen and (max-width: 610px){
    .popPlayer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #0000009e;
        z-index: 102;
        .pmain {
            background-color: #fff;
            border-radius: 4px 4px 0 0;
            overflow: hidden;
            position: absolute;
            width: 95vw;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            margin: 0;
        }
        .video-flv {
            height: 100% !important;
            max-height: 100% !important;
            width: 100% !important;
        }
        .pheader {
            height: 107px !important;
            padding: 3px 0 5px 5vw !important;
            .pcode {
                position: static !important;
                b{
                    top: 0 !important;
                }
                .drawCode li {
                    margin: 0 -3px !important;
                }
                li:last-of-type:before {
                    left: 4px !important;
                }
            }
            .pinfo{
                span {
                    margin-bottom: 0 !important;
                }
                p{
                    font-size: 12px !important;
                }
            } 
        }
    }
}
</style>