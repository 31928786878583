<template>


    <div class="misceWrap">
        <div class="miscePage about">
            <h3 class="mhide">關於我們</h3>
            <p>澳門六合彩是從1至49個號碼中選出六個為中獎號碼的獎券，由「賽馬會獎券奧門分公司」經辦。 </p>
            <p>澳門六合彩每晚攪珠一次，並於網絡現場直播。</p>
        </div>
        <h3>玩法規則</h3>
        <div class="ruleTable">
            <img src="https://00853six.com/assets/logo-5fb13fd4.png">
            <table  >
                <tr  ><td   colspan="3" class="theader">澳門六合彩注項單位為每注10澳門幣。選擇複式或膽拖注項時，可以每注澳門幣$5「部份注項單位」投注。有關之獎金則根據每注「部份注項單位」佔注項單位的份數計算。每期六合彩攪珠均從49個號碼中攪出七個號碼。首六個號碼稱為「攪出號碼」，而第七個號碼稱為「特別號碼」。 <p  >獎項詳情如下：</p></td></tr>
                <tr  ><td   class="one">頭獎</td><td   class="two">選中6個「攪出號碼」</td><td   class="three"> 獎金會因應該期獲中頭獎注數而有所不同，每期頭獎獎金基金訂為不少於澳門幣800萬元。 </td></tr><tr  ><td   class="one">二獎</td><td   class="two">選中5個「攪出號碼」+「特別號碼」</td><td   class="three">獎金會因應該期獲中二獎注數而有所不同</td></tr><tr  ><td   class="one">三獎</td><td   class="two">選中5個「攪出號碼」</td><td   class="three">獎金會因應該期獲中三獎注數而有所不同</td></tr><tr  ><td   class="one">四獎</td><td   class="two">選中4個「攪出號碼」+「特別號碼」</td><td   class="three">固定獎金澳門幣9,600元</td></tr><tr  ><td   class="one">五獎</td><td   class="two">選中4個「攪出號碼」</td><td   class="three">固定獎金澳門幣640元</td></tr><tr  ><td   class="one">六獎</td><td   class="two">選中3個「攪出號碼」+「特別號碼」</td><td   class="three">固定獎金澳門幣320元</td></tr><tr  ><td   class="one">七獎</td><td   class="two">選中3個「攪出號碼」</td><td   class="three">固定獎金澳門幣40元</td></tr>
            </table>
        </div>
    </div>

</template>

<script setup>

</script>

<style scoped lang='scss'>
.main-page-full {
    width: 100%;
    .misceWrap {
        min-height: 58vh;
        text-align: center;
        font-size: 15px;
        .ruleTable {
            margin-top: 5px;
            padding: 0 10px 30px;
            border: solid var(--border-width) var(--color-border);
            border-top: 4px solid var(--color-primary);
            img {
                height: 96px;
                margin-top: 20px;
            }
            table {
                width: 100%;
                max-width: 1060px;
                text-align: left;
                margin: 20px auto 0;
                border-spacing: 0;
                border-collapse: collapse;
                border: solid var(--border-width) var(--color-text-light);
                td {
                    line-height: 2;
                    padding: 6px 10px;
                    border: solid var(--border-width) var(--color-text-light);
                }
            }
        }
        h3 {
            font-size: 24px;
            padding: 10px 0;
            text-align: center;
            color: var(--color-primary);
            color: #2c2c2c;
        }
        .miscePage {
            padding: 40px 10px;
            max-width: 860px;
            margin: 0 auto;
            text-align: left;
            line-height: 1.8;
            word-break: break-all;
            font-size: 15px;
            margin-top: 50px;
       
        }
    }
}
@media screen and (max-width: 610px) {
    h3{
        display: none !important;
    }
    .ruleTable{
        border-top: none !important;
    }
    .about{
        margin-top: 0 !important;
  
        margin-bottom: 40px !important;
        padding: 10px 10px 20px !important  ;
        .mhide{
            display: none;
        }
  

    }
    

}
</style>