<template>
  <div id="app" style="width:100%">
    <div class="nav" style="display: none;">
      <div v-for="(v,i) in tabList" :key="i" :class="act==i?'act':''" @click="act=i">{{v.name}}</div>
    </div>
    <div class="black_div" :style="'height:'+720*scale+'px'" v-show="isblack">
      <p>澳門六合彩攪珠直播時間為每晚 <span style="color:#f00">21:32-21:38</span> (北京時間)，敬請查看。<br>
         <span style="font-size: 14px;color: #868686;" v-show="area_h!='21'">北京時間：{{ now_beijing_time }}</span><br>
         <span style="font-size: 14px;color: #868686;" v-show="area_h!='21'">您的時區直播時間為：{{ area_h }}:32-{{ area_h }}:38</span>
      </p>
    </div>
    <div v-show="!isblack">
      <div class="video" v-if="show">
        <img v-for="v in [0,1,2,3]" :key="v" class="backimg" :src="'/resource/assets/back/back'+v+'.jpg'" v-show="v==act"/>
        <div class="vmain">
          <div class="ballbox">
            <div class="ball" v-for="(v,i) in ballnum" :key="i" :style="ballstyle(i)"></div>
          </div>
          <div class="kjtime">第{{kjdata[act]['Qi']}}期</div>
          <div class="kjbox">
            <transition-group name='ball' tag="div">
            <div class="kjball" v-for="(v,i) in kjdata[act].Data" :key="i">
              <div :class="'number css'+v.number">{{v.number}}</div>
              <div class="text">{{textChange(v.sx)}}/{{textChange(v.nim)}}</div>
            </div>
            </transition-group>
          </div>
          <img class="teball" src="/resource/assets/videoSkin/fonts/ballNums/circle.png" />
        </div>
        <div class="nextkj" v-show="!kjing[act]">
          <p>下期開獎：</p>
          <p>{{kjdata[act].Moon}}月{{kjdata[act].Day}}日</p>
          <!-- <p>{{kjdata[act].Time}}</p> -->
          <p>21點32分</p>
        </div>
        <div class="kjing" v-show="kjing[act]&!ishistory" style="color: #f00;text-shadow: 1px 1px #fff;">开奖直播中</div>
        <img class="circle" src="/resource/assets/back/circle1.png" />
        <div class="svgmain">
          <div v-for="(v,i) in kjnum" :key="i" class="ball" :style="kjballstyle(i)"></div>
        </div>
        <img class="circle" src="/resource/assets/back/circle2.png" />
        <!-- <div class="qiehuan">
          <span :class="ishistory?'':'act'" @click="tozhibo()">直播</span>
          <span :class="ishistory?'act':''" @click="ishistory=true" >历史</span>
        </div> -->
        <!-- <div class="history">
          <a target="_blank" v-for="(v,i) in his" :key="i" :href="hisurl[act][i]">{{v}}</a>
        </div> -->
      </div>
      <div class="kjhis" style="display: none;">
        <div class="ti" v-if="showkjhis1">
          <div>历史开奖</div>
          <select v-model="hisyear">
            <!-- <option value="0">选择年份</option> -->
            <option :value="v.year" v-for="(v,i) in kjyears[this.act]" :key="i">{{v.year}}年</option>
          </select>
        </div>
        <div style="display: none;" class="content" v-if="showkjhis2">
          <div :class="hisi===i?'hisact':''" v-for="(v,i) in kjhis[this.act]" :key="i" @click="seehis(i)">第{{v.qishu}}期</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import axios from 'axios'
export default {
name: 'App',
components: {
  // HelloWorld
},
data(){
  return{
    // host:'http://103.240.143.143:81/data/',
    // host:'/',
    host:'https://kj6.kkjj.finance/',
    // api:'http://212.111.214.80:82/',
    api:'https://ls.kjkj.fit/',
    tabList:[
      {name:'澳門六合彩',cod:'am',time:['21','14','21','40']},
      {name:'香港六合彩',cod:'xg',time:['21','14','21','40']},
      {name:'台湾六合彩',cod:'tw',time:['20','46','20','59']},
      {name:'新加坡六合彩',cod:'xjp',time:['18','00','18','50']},
    ],
    his:['开奖记录','六和图纸','高手资料','交流大厅'],
    hisurl:[
      ['https://8.48k.asia:1888/Kj/index.html','https://tk.bmzl.us:1888/','https://k.48kk99.com/','https://k.48kk99.com/Article/lts'],
      ['https://ls.kjkj.fit/api/?xg','https://tk.bmzl.us:1888/','https://www.131hk.us:443','https://k.48kk99.com/Article/lts'],
      ['https://ls.kjkj.fit/api/?tw','https://tk.bmzl.us:1888/','https://twzl.bmzl.us:1888/','https://k.48kk99.com/Article/lts'],
      ['https://ls.kjkj.fit/api/?xjp','https://tk.bmzl.us:1888/','https://xjp.bmzl.us:1888/','https://k.48kk99.com/Article/lts']
    ],
    show:false,
    showiframe:false,
    kjing:[false,false,false,false],
    showkjhis1:false,
    showkjhis2:false,
    act:0,
    kjdata:[],
    kjyears:[],
    kjhis:[],
    hisi:'',
    hisyear:new Date().getFullYear(),
    ballnum:[],
    ballnum_bak : ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49'],
    kjnum:[],
    kjnumpositions:[
      {"x":38,"y":63},{"x":32.9,"y":60.2},{"x":28.4,"y":56.4},{"x":24.5,"y":51.5},{"x":21.6,"y":45.5},{"x":20,"y":38.8},{"x":56.5,"y":63}
    ],
    ballpositions:this.shuffle([
    {"x":59,"y":60}, {"x":31,"y":54}, {"x":48,"y":51}, {"x":25,"y":56}, {"x":54,"y":55}, {"x":42,"y":60}, {"x":10,"y":53}, {"x":75,"y":56}, {"x":2,"y":57}, {"x":14,"y":70}, {"x":63,"y":54}, {"x":51,"y":72}, {"x":59,"y":69}, {"x":26,"y":68}, {"x":45,"y":67}, {"x":70,"y":54}, {"x":32,"y":63}, {"x":32,"y":81}, {"x":15,"y":58}, {"x":40,"y":73}, {"x":36,"y":67}, {"x":45,"y":81}, {"x":69,"y":72}, {"x":73,"y":65}, {"x":39,"y":81}, {"x":22,"y":63}, {"x":20,"y":76}, {"x":60,"y":77}, {"x":26,"y":77}, {"x":33,"y":73}, {"x":75,"y":77}, {"x":58,"y":83}, {"x":84,"y":61}, {"x":51,"y":83}, {"x":67,"y":64}, {"x":10,"y":61}, {"x":6,"y":67}, {"x":66,"y":81}, {"x":39,"y":55}, {"x":85,"y":54}, {"x":50,"y":62}, {"x":79,"y":67}
    ]),
    ballpositions_bak:this.shuffle([
    {"x":59,"y":60}, {"x":31,"y":54}, {"x":48,"y":51}, {"x":25,"y":56}, {"x":54,"y":55}, {"x":42,"y":60}, {"x":10,"y":53}, {"x":75,"y":56}, {"x":2,"y":57}, {"x":14,"y":70}, {"x":63,"y":54}, {"x":51,"y":72}, {"x":59,"y":69}, {"x":26,"y":68}, {"x":45,"y":67}, {"x":70,"y":54}, {"x":32,"y":63}, {"x":32,"y":81}, {"x":15,"y":58}, {"x":40,"y":73}, {"x":36,"y":67}, {"x":45,"y":81}, {"x":69,"y":72}, {"x":73,"y":65}, {"x":39,"y":81}, {"x":22,"y":63}, {"x":20,"y":76}, {"x":60,"y":77}, {"x":26,"y":77}, {"x":33,"y":73}, {"x":75,"y":77}, {"x":58,"y":83}, {"x":84,"y":61}, {"x":51,"y":83}, {"x":67,"y":64}, {"x":10,"y":61}, {"x":6,"y":67}, {"x":66,"y":81}, {"x":39,"y":55}, {"x":85,"y":54}, {"x":50,"y":62}, {"x":79,"y":67}
    ]),
    para:[],
    para2:[],
    isRoll:[false,false,false,false],
    scale:document.body.clientWidth>=900?1:document.body.clientWidth/900,
    ishistory:false,
    animationTime:1500,
    timearr:[],
    isblack:true,
    timearea : Intl.DateTimeFormat().resolvedOptions().timeZone,
    area_h : 13 - new Date().getTimezoneOffset() / 60,
    now_beijing_time : "00:00:00"
  }
},

created(){
  // 识别请求的开奖类型
  let url = window.location.search;
  let type = 0;
  // switch (url) {
  //     case '?wf':
  //         type = 4;
  //         break;
  //     case '?xg':
  //         type = 1;
  //         break;
  //     case '?tw':
  //         type = 2;
  //         break;
  //     case '?xjp':
  //         type = 3;
  //         break;
  //     default:
  //         type = 0
  // }
  this.act=type;
  this.firstGet();
  // this.getyears();
  // this.getkjhis();
  // this.yingli(102,3,10,9.9,4);
},
watch:{
  hisyear:function(e){
    this.hisi = '';
    this.showkjhis2 = false;
    if(e==0){
      this.showkjhis2 = true;
      return;
    }
    let url = this.api+'kj';
    axios.post(url,{'g':this.tabList[this.act].cod,'y':e}).then((res)=>{
      // console.log(res);
      // this.$set(this.kjhis,this.act,res.data.data);
      this.kjhis[this.act] = res.data.data

      this.showkjhis2 = true;
    })
  },
  act:function(e){
    this.hisyear = new Date().getFullYear();
    this.hisi = '';
    if(this.ishistory){
      this.setBall();
    }else{
      this.getData(e);
    }
  }
},
mounted () {
    // 检测当前时间，在开奖时间段内则启用自动请求
    this.timer = setInterval(this.time_range, 3000);
    this.timer2 = setInterval(this.anime_range, 150);

    window.onresize = () => {
      this.scale = document.body.clientWidth>=900?1:document.body.clientWidth/900;
    }
},
beforeDestroy() {
    // 清除定时器
    clearInterval(this.timer);
    clearInterval(this.timer2);
},
methods:{
  //简体字转繁体字
  textChange(n){
      let jt = "龙马鸡猪";
      let ft = "龍馬雞豬";
      if(jt.indexOf(n)==-1){
          return n
      }else{
          let r = ft.substr(jt.indexOf(n),1);
          return r
      }
      return n;
  },
  //追号盈利率：k开始期号 m单注金额 q连追期数 c赔率 z最低盈利率
  yingli(k,m,q,c,z){
    var resultArr = [];
    var leijitou = 0;
    for(let i=1; i<=q; i++){
      var beishu = 0;
      var leiji = 0;
      for(let b=1; b<=99; b++){
        leijitou = leijitou + m * b;
        var zq =( m * b * c ) - leijitou;
        zq = zq.toFixed(1);
        var yl = zq / leijitou;
        if(yl>=z){
          beishu = b;
          leiji = leijitou;
          break;
        }else{
          leijitou = leijitou - m * b;
          continue;
        }
      }
      if(leiji == 0){
        break;
      }
      var res = {
        'xuhao':i,
        'qishu':k+i,
        'beishu':beishu,
        'leiji':leiji,
        'zq':zq,
        'yl':Math.floor(yl*100)+"%"
      }
      resultArr[i-1] = res;
    }
    // console.log(resultArr);
  },
  //切换直播
  tozhibo(){
    this.ishistory = false;
    this.getData(this.act);
    this.hisi = '';
  },
  //小球 开奖球
  setBall(){
    var kjn = this.kjnum.length;
    if(this.ishistory){
      kjn = 0;
    }
    for(let i = 0;i<this.timearr.length;i++){
      clearTimeout(this.timearr[i])
    }
    for(let i = 0;i<this.kjnum.length;i++){
      if(this.kjnum[i]!==this.kjdata[this.act].Data[i+1].number){
        this.kjnum = [];
        if(this.ishistory||this.kjing[this.act]){
          this.isRoll[this.act] = true;
        }
        break;
      }
    }
    if(this.ishistory){
      var alltime = 22000;
      var pertime = 3000;
    }else{
      alltime = 2100;
      pertime = 200;
    }
    let at = setTimeout(() => {
      this.isRoll[this.act] = false;
    }, alltime);
    this.timearr.push(at);

    var arr = [].concat(this.ballnum_bak);
    // arr = this.shuffle(arr);
    for(let i=0;i<7;i++){
      let n = this.kjdata[this.act].Data[i+1].number;
      if(parseInt(n)>0){
        let itime = 0;
        if(i>kjn){
          itime = i - kjn;
        }
        if(this.isRoll[this.act]){
          this.animationTime = 1500;
          let t = setTimeout(() => {
          //   this.$set(this.kjnum,i,n);
            this.kjnum[i] = n
          }, pertime+pertime*itime);
          this.timearr.push(t);
        }else{
          this.animationTime = 1;
          // this.$set(this.kjnum,i,n);
          this.kjnum[i] = n
        }

        for(let a=0;a<arr.length;a++){
          if(n==arr[a]){
            arr.splice(a,1)
          }
        }
      }
    }
    var l = arr.length;
    for(let i=0;i<(l-42);i++){
      arr.pop();
    }
    this.ballnum = arr;
  },
  //数组乱序
  shuffle(arr){
    for (let i = 1; i < arr.length; i++) {
      const random = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[random]] = [arr[random], arr[i]];
    }
    return arr;
  },
  //动画
  anime_range(){
    let nh = new Date().getHours() + ( new Date().getTimezoneOffset() / 60 + 8 );
    let nm = new Date().getMinutes();
    let ns = new Date().getSeconds();
    
    nh = nh<10?"0"+nh:nh;
    nm = nm<10?"0"+nm:nm;
    ns = ns<10?"0"+ns:ns;

    this.now_beijing_time = nh+":"+nm+":"+ns;

    let t = new Date();
    let h = t.getHours();
    let m = t.getMinutes();
    if(this.isRoll[this.act] && h==21 && m>30){
      for(let i=0;i<42;i++){
        let x = this.ballpositions[i].x;
        let y = this.ballpositions[i].y;
        if(x<40&&y<40){
          this.ballpositions[i].x = this.randomNum(0,49);
          this.ballpositions[i].y = this.randomNum(51,99);
          continue;
        }
        if(x>60&&y<40){
          this.ballpositions[i].x = this.randomNum(0,49);
          this.ballpositions[i].y = this.randomNum(1,49);
          continue;
        }
        if(x<40&&y>60){
          this.ballpositions[i].x = this.randomNum(51,89);
          this.ballpositions[i].y = this.randomNum(51,99);
          continue;
        }
        if(x>60&&y>60){
          this.ballpositions[i].x = this.randomNum(51,89);
          this.ballpositions[i].y = this.randomNum(1,49);
          continue;
        }
        this.ballpositions[i].x = this.randomNum(1,99);
        this.ballpositions[i].y = this.randomNum(1,99);
      }
    }else{
      for(let i=0;i<42;i++){
        this.ballpositions[i].x = this.ballpositions_bak[i].x;
        this.ballpositions[i].y = this.ballpositions_bak[i].y;
      }
    }
  },
  //时间检测函数
  time_range(){
    let t = new Date();
    let h = new Date().getHours() + ( new Date().getTimezoneOffset() / 60 + 8 );
    let m = t.getMinutes();
    if(h==21&&m>30&&m<40){
      this.isblack = false;
    }else{
      this.isblack = true;
    }
    // this.isblack = false;
    // if(this.kjnum.length<7){
    //   this.setBall();
    // }
    if(this.ishistory){
      console.log('历史开奖模式中，不进行网络请求！');
      return false;
    }
    var b = new Date();
    var e = new Date();
    var n = new Date();
    var type = this.act;
    var tplist = this.tabList;

    let kjtime = tplist[type]['time']; 
    let name = tplist[type]['name'];
    
    var str = '['+name+']开奖时间'+kjtime[0]+'：'+kjtime[1]+'——'+kjtime[2]+'：'+kjtime[3]+'，';
    
    b.setHours(kjtime[0]);
    b.setMinutes(kjtime[1]);
    e.setHours(kjtime[2]);
    e.setMinutes(kjtime[3]);
    if ( n.getTime()-b.getTime()>0 && n.getTime()-e.getTime()<0 ) {
        str = str + '正在开奖，每3秒自动获取开奖信息。';
        console.log(str);
        this.getData(type);
    } else {
        str = str + '不在开奖时间段。';
        console.log(str);
    }
  },
  //随机数
  randomNum(minNum,maxNum){
    return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10); 
  },
  //开奖小球样式
  kjballstyle(i){
    // M450,147.24V51.15s-92-6.8-169.4,62.9-79.45,148-79.45,175.2,16.16,145.78,170.08,194.25
    // M450,147.24V51.15s-92-6.8-169.4,62.9-79.45,148-79.45,175.2c0,24.28,12.88,122.2,125.08,177.25
    // M450,147.24V51.15s-92-6.8-169.4,62.9-79.45,148-79.45,175.2c0,20.86,9.5,95.44,83.32,150.94 
    // M450,147.24V51.15s-92-6.8-169.4,62.9-79.45,148-79.45,175.2c0,16.88,6.22,68.92,47.7,117.58
    // M450,147.24V51.15s-92-6.8-169.4,62.9-79.45,148-79.45,175.2c0,12.13,3.2,42.43,20.42,76.58
    // M450,147.24V51.15s-92-6.8-169.4,62.9-79.45,148-79.45,175.2A146.5,146.5,0,0,0,205,319.6
    // M450,147.24V51.15s92-6.8,169.4,62.9S699,262.08,699,289.3,686.17,434.08,532.24,482.55
    let svgs = [
      ["M", 450, ",", 147.24, "V", 51.15, "s", -92, -6.8, -169.4, ",", 62.9, -79.45, ",", 148, -79.45, ",", 175.2, ",", 16.16, ",", 145.78, ",", 170.08, ",", 194.25],
      ["M", 450, ",", 147.24, "V", 51.15, "s", -92, -6.8, -169.4, ",", 62.9, -79.45, ",", 148, -79.45, ",", 175.2, "c0", ",", 24.28, ",", 12.88, ",", 122.2, ",", 125.08, ",", 177.25],
      ["M", 450, ",", 147.24, "V", 51.15, "s", -92, -6.8, -169.4, ",", 62.9, -79.45, ",", 148, -79.45, ",", 175.2, "c0", ",", 20.86, ",", 9.5, ",", 95.44, ",", 83.32, ",", 150.94],
      ["M", 450, ",", 147.24, "V", 51.15, "s", -92, -6.8, -169.4, ",", 62.9, -79.45, ",", 148, -79.45, ",", 175.2, "c0", ",", 16.88, ",", 6.22, ",", 68.92, ",", 47.7, ",", 117.58],
      ["M", 450, ",", 147.24, "V", 51.15, "s", -92, -6.8, -169.4, ",", 62.9, -79.45, ",", 148, -79.45, ",", 175.2, "c0", ",", 12.13, ",", 3.2, ",", 42.43, ",", 20.42, ",", 76.58],
      ["M", 450, ",", 147.24, "V", 51.15, "s", -92, -6.8, -169.4, ",", 62.9, -79.45, ",", 148, -79.45, ",", 175.2, "A", 146.5, ",", 146.5, ",0,0,0,", 205, ",", 319.6],
      ["M", 450, ",", 147.24, "V", 51.15, "s", 92, -6.8, ",", 169.4, ",", 62.9, "S", 699, ",", 262.08, ",", 699, ",", 289.3, ",", 686.17, ",", 434.08, ",", 532.24, ",", 482.55]
    ];
    let svg = svgs[i];
    for(let n in svg){
      if(typeof(svg[n])=="number"){
        // console.log(this.scale)
        svg[n] = parseInt(svg[n]*this.scale*100)/100;
      }
    }
    let path = svg.join('');
    return "background-image:url('/resource/assets/videoSkin/balls/"+this.kjnum[i]+".png');offset: path('"+path+"') auto;-webkit-offset: path('"+path+"');animation-duration:"+this.animationTime+"ms";
    // return "background-image:url('/resource/assets/videoSkin/balls/"+this.kjnum[i]+".png');animation: move 1000ms forwards alternate ease-in;"
    
  },
  //小球位置
  ballstyle(i){
    return "background-image:url('/resource/assets/videoSkin/balls/"+this.ballnum[i]+".png');left:"+this.ballpositions[i].x+"%;top:"+this.ballpositions[i].y+"%;";
  },
  //请求数据
  getData(n) {
    // this.setBall();
    let t = new  Date().getTime();
    let url = this.host+'data/v_'+process.env.VUE_APP_TYPE_NAME+'.json?'+t;
    axios.get(url).then(res=>{ 
        this.setData(res.data,n);
        });
  }, 
  //加载数据
  setData(data,i){
    // this.showiframe = false;
    // console.log(data);
  //   data['Time'] = data['Time'].replace('点',':').replace('分','');
  //   this.$set(this.kjdata,i,data);
    this.kjdata[i] = data
    if(data.Data[7].nim=="-"){
      this.kjing[i] = true;
      this.isRoll[i] = true;
    }else{
      if(this.kjnum.length<7&&this.kjing[i]){
        this.isRoll[i] = true;
      }
      this.kjing[i] = false;
    }
    if(i==this.act){
      this.setBall();
    }
  },
  //初始化
  firstGet(){
    // 识别请求的开奖类型
    let url = window.location.search;
    let type = 0;
    // switch (url) {
    //     case '?xg':
    //         type = 1;
    //         break;
    //     case '?tw':
    //         type = 2;
    //         break;
    //     case '?xjp':
    //         type = 3;
    //         break;
    //     default:
    //         type = 0
    // }
    this.act=type;
    //请求数据
    let t = new  Date().getTime();
    let url1 = this.host+'data/v_'+process.env.VUE_APP_TYPE_NAME+'.json?'+t;

    Promise.all([
      new Promise((resolve)=>{
          axios(url1).then((res)=>{
              this.setData(res.data,0);
              resolve(res);
          })
      }),
      // new Promise((resolve)=>{
      //     axios(url2).then((res)=>{
      //         this.setData(res.data,1);
      //         resolve(res);
      //     })
      // })
    ]).then(()=> {
      this.show=true;
    })
  },
  //获取开奖年份
  getyears:function(){
    let url = this.api+'kjyears';
    Promise.all([
      new Promise((resolve)=>{
        axios.post(url,{'g':this.tabList[0].cod}).then((res)=>{
          // this.$set(this.kjyears,0,res.data.data);
          this.kjyears[0] = res.data.data
          resolve(res);
        })
      }),
      new Promise((resolve)=>{
        axios.post(url,{'g':this.tabList[1].cod}).then((res)=>{
          // this.$set(this.kjyears,1,res.data.data);
          this.kjyears[1] = res.data.data
          resolve(res);
        })
      }),
      // new Promise((resolve)=>{
      //   axios.post(url,{'g':this.tabList[2].cod}).then((res)=>{
      //     // this.$set(this.kjyears,2,res.data.data);
      //     this.kjyears[2] = res.data.data
      //     resolve(res);
      //   })
      // }),
      // new Promise((resolve)=>{
      //   axios.post(url,{'g':this.tabList[3].cod}).then((res)=>{
      //     // this.$set(this.kjyears,3,res.data.data);
      //     this.kjyears[3] = res.data.data
      //     resolve(res);
      //   })
      // }),
    ]).then(()=> {
      this.showkjhis1 = true;
    })
  },
  //获取历史开奖
  getkjhis:function(){
    let url = this.api+'kj';
    let year = new Date().getFullYear();
    Promise.all([
      new Promise((resolve)=>{
        axios.post(url,{'g':this.tabList[0].cod,'y':year}).then((res)=>{
          // this.$set(this.kjhis,0,res.data.data);
          this.kjhis[0] = res.data.data;
          resolve(res);
        })
      }),
      new Promise((resolve)=>{
        axios.post(url,{'g':this.tabList[1].cod,'y':year}).then((res)=>{
          // this.$set(this.kjhis,1,res.data.data);
          this.kjhis[1] = res.data.data;
          resolve(res);
        })
      }),
      new Promise((resolve)=>{
        axios.post(url,{'g':this.tabList[2].cod,'y':year}).then((res)=>{
          // this.$set(this.kjhis,2,res.data.data);
          this.kjhis[2] = res.data.data;
          resolve(res);
        })
      }),
      new Promise((resolve)=>{
        axios.post(url,{'g':this.tabList[3].cod,'y':year}).then((res)=>{
          // this.$set(this.kjhis,3,res.data.data);
          this.kjhis[3] = res.data.data;
          resolve(res);
        })
      }),
    ]).then(()=> {
      this.showkjhis2 = true;
    })
  },
  //查看历史开奖
  seehis(i){
    this.hisi = i;
    this.ishistory = true;
    let data = this.kjhis[this.act][i];
    let num = data.num.split(',');
    let sx = data.shengxiao.split(',');
    let wx = data.wuxing.split(',');
    this.kjdata[this.act]['Qi'] = data.qishu;
    let arr = {};
    for(i=0;i<7;i++){
      let a = {'number':num[i],'sx':sx[i],'nim':wx[i]};
      arr[i+1] = a;
    }
    
    this.kjdata[this.act]['Data'] = arr;
    this.setBall()
  }
}
}
</script>

<style>

.video{
  text-align: center;
}
.nav{
display: flex;
justify-content: space-around;
}
.nav div{
width: 100%;
border: 1px solid #ffca00;
line-height: 2rem;
background: #4a78ff;
color: #fff;
font-family: '宋体';
cursor: pointer;
font-size: .8rem;
}
.nav .act{
color: #f00;
background: #fff;
font-weight: 900;
}
iframe{
width: 100%;
height: 64vw;
max-height: calc(100vh - 38px);
max-height: 580px;
}
.video{
width: 100%;
padding-left: 100%;
padding-top: 80%;
position: relative;
}
.backimg{
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}
.vmain{
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
background-size:100% 100%;
}
.circle{
width: 100%;
position: absolute;
top:0;
left:0;
}
.ballbox{
position: absolute;
border-radius: 50%;
max-width: 450px;
overflow:hidden;

top:6vw;
left: 25vw;
width: 50vw;
height: 50vw;
}
.ball{
position: absolute;
border-radius: 50%;
background-size: contain;
top:0;
left:0;
transition: all .16s ease-out;

width: 5.8vw;
height: 5.8vw;
}
.kjtime{
display: inline-block;
left: 2%;
position: absolute;
top: 68%;
color: #f00;
font-weight: 900;
text-shadow: 0px 0px 3px #fff;
-webkit-text-stroke: thin #fff;
letter-spacing: 5px;

font-size: 4vw;
}
.kjbox{
position: absolute;
width: 98%;
margin: 0 auto;
top: 76.2%;
left: 1%;
}
.kjbox>div{
display: flex;
justify-content: start;
}
.kjbox .kjball{
width: 14.2%;
z-index: 1;

height: 18vw;
}
.kjbox .kjball .number{
height: 8vw;
width: 8vw;
max-height: 72px;
max-width: 72px;
border-radius: 50%;
margin: 0 auto;
background-size: contain;
font-weight: 900;
color: #000;
text-align: center;
line-height: 8vw;
font-size: 3vw;
}
.kjbox .kjball .text{
color: #fff;
font-weight: 900;
text-shadow: 1px 1px 2px #ff0000;
-webkit-text-stroke: thin #ff0000;

height: 10vw;
font-size: 4.5vw;
line-height: 12vw;
}
.history{
position: absolute;
top: 10%;
left: 82%;

font-size: 3.5vw;
}
.qiehuan{
position: absolute;
top: 10%;
right: 2%;
background: #e2e2e2;
padding: 2px;
display: flex;

font-size: 3.5vw;
}
.qiehuan span{
padding: 3px 7px;
border: 1px solid #f00;
display: block;
}
.qiehuan .act{
background: #f00;
color: #fff;
}
.history a{
text-decoration: none;
color: #fff;
display: block;
background: #6687ff;

margin: 4vw 0;
border-radius: 1vw;
padding: 1vw;
box-shadow: 0.2vw 0.2vw 0.2vw 0px #3b3b3b;
}
.nextkj,.kjing{
position: absolute;
color: #000;
right: 2%;
top: 14%;
font-weight: 900;
background: #a1f0ffa8;

padding: .5vw;
letter-spacing: .2vw;
border-radius: 1vw;
font-size: 3vw;
}
.kjhis .content{
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
overflow-y:scroll;
max-height: calc(100vh - 71px - 80vw);
}
@media screen and (min-width:900px) {
.qiehuan{
  font-size: 31.5px;
}
.ballbox{
  top:54px;
  left: 225px;
  width: 450px;
  height: 450px;
}
.ball{
  width:52.2px;
  height:52.2px;
}
.kjtime{
  font-size: 36px;
}
.kjbox .kjball{
  height: 162px;
}
.kjbox .kjball .number{
  line-height: 72px;
  font-size: 27px;
}
.kjbox .kjball .text{
  height: 90px;
  font-size: 40.5px;
  line-height: 108px;
}
.history{
  font-size: 31.5px;
}
.history a{
  margin: 18px 0;
  border-radius: 9px;
  padding: 9px;
  box-shadow: 1.8px 1.8px 1.8px 0px #3b3b3b;
}
.nextkj,.kjing{
  padding:4.5px;
  letter-spacing: 1.8px;
  border-radius: 9px;
  font-size: 27px;
}
.kjhis .content{
  max-height: calc(100vh - 791px);
}
}
.kjhis {
width: 100%;
padding: 5px;
}
.kjhis .ti{
display: flex;
justify-content: space-between;
border-bottom: 1px solid #b6b6b6;
padding-bottom: 5px;
}
.kjhis .content div{
border: 1px solid #ababab;
margin: 3px 5px;
padding: 2px 12px;
cursor: pointer;
}
.css05, .css06, .css11, .css16, .css17, .css21, .css22, .css27, .css28, .css32, .css33, .css38, .css39, .css43, .css44, .css49 {
background-image: url('/public/resource/assets/videoSkin/fonts/ballNums/greenBall.png');
}
.css01, .css02, .css07, .css08, .css12, .css13, .css18, .css19, .css23, .css24, .css29, .css30, .css34, .css35, .css40, .css45, .css46 {
background-image: url('/public/resource/assets/videoSkin/fonts/ballNums/redBall.png');
}
.css03, .css04, .css09, .css10, .css14, .css15, .css20, .css25, .css26, .css31, .css36, .css37, .css41, .css42, .css47, .css48 {
background-image: url('/public/resource/assets/videoSkin/fonts/ballNums/blueBall.png');
}
.teball {
position: absolute;
top: 74.8%;
left: 86.4%;
width: 10vw;
max-width: 90px;
z-index: 0;
animation: loading 1s linear infinite;
}
@keyframes loading {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(-360deg);
  }
}
.ball-enter-from{
opacity: 0;
/* transform:translateY(-40vw); */
}
.ball-move{
transition: all .3s;
}
.hisact{
background: #f00;
color: #fff;
}
.svgmain{
width: 100%;
height: 100%;
left: 0;
position: absolute;
top: 0;
}
.svgmain .ball{
animation: move forwards alternate ease-in;
}
@keyframes move {
  0% {
    offset-distance: 0%;
    opacity: 0.5;
  }
  20% {
    opacity: 0.5;
    offset-distance: 16%;
  }
  30% {
    opacity: 1;
    transform: rotate(0deg);
  }
  90%{
    offset-distance: 100%;
  }
  94%{
    offset-distance: 94%;
  }
  96%{
    offset-distance: 100%;
  }
  98%{
    offset-distance: 98%;
  }
  100% {
    offset-distance: 100%;
    transform: rotate(720deg);
  }
}
.black_div{
width:100%;
color:#fff;
text-align: center;
position: relative;
text-align: center;
}
.black_div p{
width: 100%;
position: absolute;
top: 45%;
}
</style>