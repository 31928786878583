//将拦截器整体导入
import request from '../utils/Interceptor'//导入已经写好的拦截器
 
// 封装所有的API接口
 
export function record(params){

  return request({
    url:'api/get_record',
    method :'get',
    params:params,
  })
}

export function prize(params){

  return request({
    url:'http://103.240.143.143:81/data/v_48am.json',
    method :'get',
    params:params,
  })
}
 

 