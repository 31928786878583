import { createRouter, createWebHistory } from 'vue-router'

import index from '@/views/index.vue'
import page from '@/views/home/page.vue'
import faq from '@/views/home/faq.vue'
import live from '@/views/home/live.vue'
import post from '@/views/home/post.vue'
import us from '@/views/home/us.vue'
import contact from '@/views/home/contact.vue'

import postIndex from '@/views/home/post/index.vue'
import api from '@/views/home/post/api.vue'

import faqIndex from '@/views/home/faq/index.vue'
import tech from '@/views/home/faq/tech.vue'
import notice from '@/views/home/faq/notice.vue'

import usIndex from '@/views/home/us/index.vue'
import rule from '@/views/home/us/rule.vue'
import policy from '@/views/home/us/policy.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: index,
    redirect: '/',
    children:[
      {
        path:'/',

        redirect:'/home'
      },
      {
        path: '/home',
        name: 'page',
        component:page,
      },
      {
        path: '/faq',
        name: 'faq',
        component:faq,
        children:[
          {
            path: '/faq',
            name: 'faqIndex',
            component:faqIndex,
          },
          {
            path: '/faq/tech',
            name: 'tech',
            component:tech,
          },
          {
            path: '/faq/notice',
            name: 'notice',
            component:notice,
          },
        ]
      },
      {
        path: '/live',
        name: 'live',
        component:live,
      },
      {
        path: '/post',
        name: 'post',
        component:post,
        children:[{
          path: '/post',
          name: 'post_index',
          component:postIndex,
        },
          {
            path: '/post/api',
            name: 'post_api',
            component:api,
          },
        ]
      },
      {
        path: '/us',
        name: 'us',
        component:us,
        children:[
          {
            path: '/us',
            name: 'usIndex',
            component:usIndex,
          },
          {
            path: '/us/rule',
            name: 'rule',
            component:rule,
          },
          {
            path: '/us/policy',
            name: 'policy',
            component:policy,
          },
          {
            path: '/us/contact',
            name: 'usContact',
            component:contact,
          },
        ]
      },
      {
        path: '/contact',
        name: 'contact',
        component:contact,
      },
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
    name: 'notFound',
    hidden: true
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
