<template>
  <div class="misceWrap">
    <div class="hisHeader one">
      <p>本網站提供查詢接口，供查詢開獎數據。</p>
      <hr>
      <p>地址: https://kj6.kkjj.finance/data/v_{{type_name}}.json</p>
      <p>方式: GET</p>
      <p>返回: JSON</p>
      <br>
      <p>調用示例</p>
      <p>澳門六合彩: https://kj6.kkjj.finance/data/v_{{type_name}}.json</p>
      <br>
      <p>返回示例</p>
      <p>查詢澳門六合彩開獎數據</p>
      <pre style="font-size: 14px;">
{
"Data":
{
  {"number":"31","sx":"鼠","nim":"金","color":"blue"},
  {"number":"03","sx":"牛","nim":"木","color":"blue"},
  {"number":"01","sx":"虎","nim":"水","color":"red"},
  {"number":"38","sx":"兔","nim":"火","color":"green"},
  {"number":"25","sx":"猴","nim":"土","color":"blue"},
  {"number":"10","sx":"蛇","nim":"金","color":"blue"},
  {"number":"46","sx":"羊","nim":"木","color":"red"}
},
"Day":"18",
"Year":"2023",
"Moon":"06",
"Nq":"169",
"Qi":"168",
"Time":"21点33分",
"Week":"周二",
"Auto":false
}
      </pre>
      <p>返回字段說明</p>
      <p>1、Data: 开奖数据；</p>
      <p>2、number: 消息描述；sx：生肖；nim：五行；color：波色（red红，green绿，blue蓝）；</p>
      <p>3、Day：开奖日期；Year：开奖年份；Moon：开奖月份；Nq：下期开奖期数；Qi：开奖期数；Time：开奖时间；Week：开奖星期。</p>
      <br>
      <hr>
      <p>地址: https://jl.tukuapi.com/api/get_record</p>
      <p>方式: GET</p>
      <p>返回: JSON</p>
      <br>
      <p>調用示例</p>
      <p>澳門六合彩: https://jl.tukuapi.com/api/get_record?lottery_id={{type_code}}</p>
      <br>
      <p>返回示例</p>
      <p>查詢澳門六合彩历史開獎數據</p>
      <pre style="font-size: 14px;">
{
"code":200,
"message":"成功",
"data":[
  {
    attr: "土,火,火,土,火,水,火",
    color: "red,red,red,blue,red,green,red",
    create_time: "2023-06-18 21:39:00",
    open_number: "18,07,30,25,46,11,29",
    periods: "169",
    sx: "狗,鸡,狗,兔,马,蛇,猪",
    year: "2023" ,
  },
  ...
]
}
      </pre>
      <p>返回字段說明</p>
      <p>1、code: 消息编码，200代表成功；</p>
      <p>2、message：消息描述；</p>
      <p>3、open_number：开奖号码；create_time：开奖时间；periods：开奖期数；year：开奖年份；sx：生肖属性；attr：五行属性；color：波色属性（red红，green绿，blue蓝）。</p>
    </div>
    <div data-v-a83bd3b0="" style="height: 50px;"></div>
  </div>
</template>

<script setup>
const type_name = process.env.VUE_APP_TYPE_NAME;
const type_code = process.env.VUE_APP_TYPE_CODE;
</script>

<style scoped lang='scss'>
.misceWrap{
  line-height: 28px;
  padding:2% 4%;
}
</style>